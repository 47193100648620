<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="unitLoad">
                <b-row class="details">
                    <b-col lg="11" sm="12" class="m-auto">
                        <b-row>
                            <b-col class="col-md-3 border-right">
                                <div class="d-flex flex-column align-items-center text-center p-3 py-5">
                                    <img v-if="profile.profile_image" class="rounded-circle mt-5" width="150px" :src="url+'/storage/research-farmer-profile/' + profile.profile_image" alt="Profie image">
                                    <span class="font-weight-bold">{{ currentLocale === 'bn' ? profile.name_bn : profile.name }}</span>
                                    <span></span>
                                </div>
                            </b-col>
                            <b-col class="col-md-9">
                                <div class="p-3 py-5">
                                    <div class="d-flex justify-content-between align-items-center mb-2">
                                        <h4 class="text-left">{{ $t('globalTrans.personal_information') }}</h4>
                                        <div v-if="!profile.profile_image" class="border px-2 py-1 add-experience text-right">
                                            <b-link to="/research-farmer/profile-update">
                                                <i class="fa fa-edit"></i>&nbsp;{{ $t('globalUserData.update_profile') }}
                                            </b-link>
                                        </div>
                                    </div>
                                    <b-table-simple bordered striped small>
                                        <tbody>
                                            <b-tr>
                                                <b-td>{{$t('globalTrans.father_name')}}</b-td>
                                                <b-td>{{ currentLocale === 'bn' ? profile.father_name_bn : profile.father_name }}</b-td>
                                                <b-td rowspan="7" style="width: 1% !important;" class="text-center">
                                                    <div class="font-weight-normal">{{ $t('globalTrans.nid_photo') }}</div>
                                                    <img v-if="profile.nid_image" width="150px" height="185px" :src="url+'storage/research-farmer-profile/' + profile.nid_image" alt="NID image">
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('globalTrans.mother_name')}}</b-td>
                                                <b-td>{{ (currentLocale === 'bn') ? profile.mother_name_bn : profile.mother_name }}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('globalTrans.nid')}}</b-td>
                                                <b-td>{{ $n(profile.nid, { useGrouping: false }) }}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td width="20%">{{ $t('globalUserData.gender') }}</b-td>
                                                <b-td>{{ currentLocale === 'bn' ? profile.gender_name_bn : profile.gender_name }}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('globalUserData.mobile_no')}}</b-td>
                                                <b-td>{{ (currentLocale == 'bn' ? '০' : '0') + $n(profile.mobile_no, { useGrouping: false }) }}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('globalTrans.email')}}</b-td>
                                                <b-td>{{ profile.email }}</b-td>
                                            </b-tr>
                                            <b-tr v-if="profile.occupation || profile.occupation_bn">
                                                <b-td>{{$t('globalTrans.occupation')}}</b-td>
                                                <b-td>{{ (currentLocale === 'bn') ? profile.occupation_bn : profile.occupation }}</b-td>
                                            </b-tr>
                                        </tbody>
                                    </b-table-simple>
                                    <div class="d-flex justify-content-between align-items-center mb-2">
                                        <h4 class="text-right">{{ $t('globalTrans.address') }}</h4>
                                    </div>
                                    <b-table-simple striped bordered small>
                                        <tbody>
                                            <b-tr>
                                                <b-td>{{$t('org_pro.area_type')}}</b-td>
                                                <b-td>{{ getAreaTypeList(profile.area_type_id) }}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('globalTrans.division')}}</b-td>
                                                <b-td>{{ (currentLocale === 'bn') ? profile.division_name_bn : profile.division_name }}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('globalTrans.district')}}</b-td>
                                                <b-td>{{ (currentLocale === 'bn') ? profile.district_name_bn : profile.district_name }}</b-td>
                                            </b-tr>
                                            <b-tr v-if="profile.city_corporation_id">
                                                <b-td>{{$t('globalTrans.city_corporation')}}</b-td>
                                                <b-td>{{ (currentLocale === 'en') ? profile.city_corporation_name : profile.city_corporation_name_bn }}</b-td>
                                            </b-tr>
                                            <b-tr v-if="profile.pouroshova_id">
                                                <b-td>{{$t('globalTrans.pouroshova')}}</b-td>
                                                <b-td>{{ (currentLocale === 'bn') ? profile.pauroshova_name : profile.pauroshova_name_bn }}</b-td>
                                            </b-tr>
                                            <b-tr v-if="profile.upazila_id">
                                                <b-td>{{$t('globalTrans.upazila')}}</b-td>
                                                <b-td>{{ (currentLocale === 'bn') ? profile.upazila_name_bn : profile.upazila_name }}</b-td>
                                            </b-tr>
                                            <b-tr v-if="profile.union_id">
                                                <b-td>{{$t('globalTrans.union')}}</b-td>
                                                <b-td>{{ (currentLocale === 'bn') ? profile.union_name_bn : profile.union_name }}</b-td>
                                            </b-tr>
                                            <b-tr v-if="profile.village_bn || profile.village">
                                                <b-td width="20%">{{ $t('globalTrans.village') }}</b-td>
                                                <b-td>{{ (currentLocale === 'bn') ? profile.village_bn : profile.village }}</b-td>
                                            </b-tr>
                                        </tbody>
                                    </b-table-simple>
                                    <div class="d-flex justify-content-between align-items-center mb-2">
                                        <h4 class="text-right">{{ $t('external_research.application_info') }}</h4>
                                    </div>
                                    <b-table-simple striped bordered small>
                                        <tbody>
                                            <b-tr>
                                                <b-td width="20%">{{ $t('globalTrans.status') }}</b-td>
                                                <b-td>
                                                    <span v-if="profile.status === 1" class="badge badge-success">{{ $t('globalTrans.saved') }}</span>
                                                    <span v-else class="badge badge-danger">{{ $t('globalTrans.pending') }}</span>
                                                </b-td>
                                            </b-tr>
                                        </tbody>
                                    </b-table-simple>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { profileListData } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'

export default {
    mixins: [ModalBaseMasterList],
    components: {
    },
    data () {
        return {
            unitLoad: false,
            profile: {
            },
            url: agriResearchServiceBaseUrl
        }
    },
    created () {
        if (this.authUser.user_id) {
            this.loadAuthProfileData()
        }
    },
    mounted () {
        core.index()
    },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        getAreaTypeList (status) {
            if (status === 1) {
                return this.$i18n.locale === 'en' ? 'City Corporation' : 'সিটি কর্পোরেশন'
            } else if (status === 2) {
                return this.$i18n.locale === 'en' ? 'Pauroshoba' : 'পৌরসভা'
            } else if (status === 3) {
                return this.$i18n.locale === 'en' ? 'Union' : 'ইউনিয়ন'
            }
        },
        loadAuthProfileData () {
            this.unitLoad = true
            RestApi.getData(agriResearchServiceBaseUrl, profileListData, { user_id: this.authUser.user_id }).then(response => {
                if (response.success) {
                    this.profile = this.getRelationalData(response.data)
                    this.unitLoad = false
                } else {
                    this.profile = this.authUser
                    this.unitLoad = false
                    this.$router.push('/research-farmer/profile-update')
                }
            })
        },
        getRelationalData (data) {
            const tmpDivision = this.$store.state.ExternalUserIrrigation.commonObj.divisionList.find(newItem => newItem.value === data.division_id)
            const tmpDistrict = this.$store.state.ExternalUserIrrigation.commonObj.districtList.find(newItem => newItem.value === data.district_id)
            const tmpUpazila = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.find(newItem => newItem.value === data.upazila_id)
            const tmpUnion = this.$store.state.ExternalUserIrrigation.commonObj.unionList.find(newItem => newItem.value === data.union_id)
            const tmpGender = this.$store.state.ExternalUserIrrigation.genderList.find(newItem => newItem.value === data.gender)
            const tmpCityCorporation = this.$store.state.ExternalUserIrrigation.commonObj.cityCorporationList.find(newItem => newItem.value === data.city_corporation_id)
            const tmpPauroshova = this.$store.state.ExternalUserIrrigation.commonObj.pauroshobaList.find(newItem => newItem.value === data.pouroshova_id)
            const newData = {
                division_name: tmpDivision !== undefined ? tmpDivision.text_en : '',
                division_name_bn: tmpDivision !== undefined ? tmpDivision.text_bn : '',
                district_name: tmpDistrict !== undefined ? tmpDistrict.text_en : '',
                district_name_bn: tmpDistrict !== undefined ? tmpDistrict.text_bn : '',
                upazila_name: tmpUpazila !== undefined ? tmpUpazila.text_en : '',
                upazila_name_bn: tmpUpazila !== undefined ? tmpUpazila.text_bn : '',
                union_name: tmpUnion !== undefined ? tmpUnion.text_en : '',
                union_name_bn: tmpUnion !== undefined ? tmpUnion.text_bn : '',
                gender_name: tmpGender !== undefined ? tmpGender.text_en : '',
                gender_name_bn: tmpGender !== undefined ? tmpGender.text_bn : '',
                city_corporation_name: tmpCityCorporation !== undefined ? tmpCityCorporation.text_en : '',
                city_corporation_name_bn: tmpCityCorporation !== undefined ? tmpCityCorporation.text_bn : '',
                pauroshova_name: tmpPauroshova !== undefined ? tmpPauroshova.text_en : '',
                pauroshova_name_bn: tmpPauroshova !== undefined ? tmpPauroshova.text_bn : ''
            }
            return Object.assign({}, data, newData)
        }
    }
}

</script>
<style lang="scss">
    .details {
    h5 {
      color: green !important;
      font-size: 20px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
  }
  .hidden_header {
    display: none
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
.add-experience:hover {
    background: #c3cfd8;
    color: #fff;
    border: solid 1px #BA68C8
}
</style>
